import React from "react"

class MastermindComponent extends React.Component {

  codeOptionsClassic = [
    {
      number: 1,
      color: "white",
      letter: "A",
    },
    {
      number: 2,
      color: "pink",
      letter: "B"
    },
    {
      number: 3,
      color: "green",
      letter: "C"
    },
    {
      number: 4,
      color: "red",
      letter: "D"
    },
    {
      number: 5,
      color: "orange",
      letter: "E"
    },
    {
      number: 6,
      color: "grey",
      letter: "F"
    },
  ]

  codeOptionsNew = [
    {
      number: 1,
      color: "white",
      letter: "A",
    },
    {
      number: 2,
      color: "pink",
      letter: "B"
    },
    {
      number: 3,
      color: "green",
      letter: "C"
    },
    {
      number: 4,
      color: "red",
      letter: "D"
    },
    {
      number: 5,
      color: "orange",
      letter: "E"
    },
    {
      number: 6,
      color: "grey",
      letter: "F"
    },
    {
      number: 7,
      color: "yellow",
      letter: "G"
    },
    {
      number: 8,
      color: "blue",
      letter: "H"
    },
  ]

  constructor(props) {
    super(props);
    this.state = {
      code: [],
      guesses: [],
      currentGuess: [],
      codeType: 'NUMBER',
      hasWon: false,
      gameStarted: false,
      debug: false,
      codeOptions: this.codeOptionsClassic,
      codeOptionsValue: 'CLASSIC',
      codeDigits: 4,
    };
  }

  newGame = () => {
    let code = []
    for(let i = 0; i < this.state.codeDigits; i++) {
      code.push(Math.floor((Math.random() * this.state.codeOptions.length) + 1));
    }

    let currentGuess = [];
    for(let i = 0; i < this.state.codeDigits; i++) {
      currentGuess.push(1);
    }

    this.setState({
      code: code,
      guesses: [],
      currentGuess: currentGuess,
      hasWon: false,
      gameStarted: true,
    });
  }

  setGuessDigit = (digit, option) => {
    let currentGuess = Array.from(this.state.currentGuess);

    currentGuess[digit] = +option;

    this.setState({
      currentGuess: currentGuess,
    });
  }

  guess = () => {
    let guess = Array.from(this.state.currentGuess);
    let code = this.state.code;
    let validation = {
      correct: 0,
      wrongDigit: 0,
    }

    let correctDigits = []
    for(let i = 0; i < guess.length; i++) {
      // If empty, invalid guess
      if(guess[i] === 0) {
        return;
      }
      // If correct digit and value
      if(guess[i] === code[i]) {
        validation.correct += 1;
        correctDigits.push(i);
      }
    }

    let wrongPos = []
    // Check if correct value but wrong digit
    for(let i = 0; i < guess.length; i++) {
      if(correctDigits.indexOf(i) > -1) {
        continue;
      }
      for(let k = 0; k < code.length; k++) {
        if(correctDigits.indexOf(k) > -1 || wrongPos.indexOf(k) > -1) {
          continue;
        }
        if(guess[i] === code[k]) {
          validation.wrongDigit += 1;
          wrongPos.push(k);
          break;
        }
      }
    }

    let guesses = this.state.guesses;

    guesses.push({
      validation: validation,
      digits: guess,
    });

    let hasWon = validation.correct === 4

    this.setState({
      guesses: guesses,
      hasWon: hasWon,
    });
  }

  changeCodeType = (value) => {
    this.setState({
      codeType: value,
    })
  }

  changeDebug = (value) => {
    this.setState({
      debug: value === 'true',
    })
  }

  changeCodeOptions = (value) => {
    let codeOptions;
    switch (value) {
      case 'NEW':
        codeOptions = this.codeOptionsNew;
        break;
      case 'CLASSIC':
      default:
        codeOptions = this.codeOptionsClassic;
    }

    this.setState({
      codeOptions: codeOptions,
      codeOptionsValue: value,
    })
  }

  changeCodeDigits = (value) => {
    this.setState({
      codeDigits: +value,
    })
  }

  resetGame = () => {
    this.setState({
      gameStarted: false,
    })
  }

  render() {
    let {
      code,
      guesses,
      currentGuess,
      codeType,
      hasWon,
      gameStarted,
      debug,
      codeOptions,
      codeDigits,
    } = this.state;

    if(debug) {
      console.log(this.state);
    }

    const guesser = [];

    for(let i = 0; i < codeDigits; i++) {
      guesser.push(<select className={'p-2'} style={{color: 'black'}} onChange={e => this.setGuessDigit(i, e.target.value)}>
        {codeOptions.map((value, index) => {
          if(value.number === 0) {
            return <option key={index} value={value.number}> </option>;
          }
          switch (codeType) {
            case 'COLOR':
              return <option key={index} value={value.number}>{value.color}</option>;
            case 'LETTER':
              return <option key={index} value={value.number}>{value.letter}</option>;
            case 'NUMBER':
            default:
              return <option key={index} value={value.number}>{value.number}</option>;
          }
        })}
      </select>)
    }

    return (
      <div>
        <div className={'flex'}>
          <div className={'p-5'}>
            <button onClick={this.resetGame}>New Game</button>
          </div>
          <div className={'p-5'}>
            Code Type:
            <select
              style={{color: 'black'}}
              onChange={e => this.changeCodeType(e.target.value)}
            >
              <option value={'NUMBER'}>Numbers</option>
              <option value={'LETTER'}>Letters</option>
              <option value={'COLOR'}>Colors</option>
            </select>
          </div>
          <div className={'p-5'}>
            Debug:
            <select style={{color: 'black'}} onChange={e => this.changeDebug(e.target.value)}>
              <option value={'false'}>False</option>
              <option value={'true'}>True</option>
            </select>
          </div>
          {debug && <div>
            Debug:
            <div>
              Code: { code }
            </div>
            <div>
              Current Guess: { currentGuess }
            </div>
          </div>}
        </div>
        {!gameStarted && <div style={{position: "absolute", top: '50%', width: '100%', textAlign: "center"}}>
          <div className={'p-4'}>
            <button className={'text-3xl'} onClick={this.newGame}>Start Game</button>
          </div>
          <div className={'p-4'}>
            Values:
            <select
              style={{color: 'black'}}
              onChange={e => this.changeCodeOptions(e.target.value)}
              value={this.state.codeOptionsValue}
            >
              <option value={'CLASSIC'}>6</option>
              <option value={'NEW'}>8</option>
            </select>
          </div>
          <div className={'p-4'}>
            Digits:
            <select
              style={{color: 'black'}}
              onChange={e => this.changeCodeDigits(e.target.value)}
              value={this.state.codeDigits}
            >
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>}
        {gameStarted && <div className={'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'}>
          {hasWon && <div className={'mx-auto w-96 text-center'}>
            <p>You Won!</p>
          </div>}
          {!hasWon && <div className={'mx-auto w-96'}>
            {guesser}
            <button className={'p-4'} onClick={this.guess}>Guess</button>
          </div>}
          <div className={'mx-auto w-96'}>
            {guesses.map((guess, index) => {
              return <div className="flex flex-row items-center w-full" key={'guesses-' + index}>
                {guess.digits.map((digit, index2) => {
                  switch (codeType) {
                    case 'COLOR':
                      return <div
                        className={'p-5'}
                        key={'guesses-' + index + '-digit-' + index2}
                        style={{backgroundColor: codeOptions[digit-1].color}}> </div>;
                    case 'LETTER':
                      return <div
                        className={'p-4'}
                        key={'guesses-' + index + '-digit-' + index2}
                      >{codeOptions[digit-1].letter}</div>;
                    case 'NUMBER':
                    default:
                      return <div
                        className={'p-4'}
                        key={'guesses-' + index + '-digit-' + index2}
                      >{codeOptions[digit-1].number}</div>;
                  }
                })}
                <div className={'p-4'}>
                  <div>
                    Correct: {guess.validation.correct}
                  </div>
                  <div>
                    Wrong pos: {guess.validation.wrongDigit}
                  </div>
                </div>
              </div>;
            })}
          </div>
        </div>}
      </div>
    );
  }
}

export default MastermindComponent;
